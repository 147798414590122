import * as React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { isMobileState } from '@/recoil/layout'
import { marketCodeManager } from '@/recoil/marketCode'
import { useRecoilState, useRecoilValue } from 'recoil'

import Layout from '@/layouts/Default'
import HomeHero from '@/components/HomeHero'
import WinesSlider from '@/components/WinesSlider'
import WinerySlider from '@/components/WinerySlider'
import Quote from '@/components/Block/Quote'
import BlockTextImages from '@/components/Block/TextImages'
import Community from '@/components/Community'
import StoreLocator from '@/components/StoreLocator'

import CircularLabelButton from '@/components/CircularLabelButton'
import Button from '@/components/Button'
import NewsSlider from '@/components/NewsSlider'

// import staticData from './home.data.json'

const IndexPage = ({ data, pageContext: { pagePath, marketCode: pageMarketCode } }) => {
  if (!data) return null

  const isMobile = useRecoilValue(isMobileState)
  const [marketCode, setMarketCode] = useRecoilState(marketCodeManager)

  const meta = {
    title: data.drupal.nodeById?.fieldHomeMetaTags?.entity?.fieldMetaTitle,
    description: data.drupal.nodeById?.fieldHomeMetaTags?.entity?.fieldMetaDescription,
    bypassAgeGate: data.drupal.nodeById?.fieldHomeMetaTags?.entity?.fieldMetaTagsBypassAgeGate,
    slug: data.drupal.nodeById?.fieldHomeMetaTags?.entity?.fieldMetaSlugUrl
  }

  const heroData = data.drupal.nodeById.fieldHomeHeroSlider.entity?.fieldStandardSliderItem.map((item) => ({
    subtitle: item.entity?.fieldHeroSubtitle,
    title: item.entity?.fieldHeroTitle,
    text: item.entity?.fieldHeroDescription,
    ctaLabel: item.entity?.fieldHeroCta?.title,
    ctaTo: item.entity?.fieldHeroCta?.url?.path,
    background: isMobile ? item.entity?.fieldBackgroundMobile?.url : item.entity?.fieldBackgroundDesktop?.url,
    revealDate: item.entity?.fieldRevealDate?.date,
    standardSlideBackground: isMobile ? item.entity?.fieldStandardSlide?.entity?.fieldBackgroundMobile?.url : item.entity?.fieldStandardSlide?.entity?.fieldBackgroundDesktop?.url,
    standardSlideTitle: item.entity?.fieldStandardSlide?.entity?.fieldHeroTitle,
    standardSlideSubtitle: item.entity?.fieldStandardSlide?.entity?.fieldHeroSubtitle,
    standardSlideDescription: item.entity?.fieldStandardSlide?.entity?.fieldHeroDescription,
    standardSlideCtaLabel: item.entity?.fieldStandardSlide?.entity?.fieldHeroCta?.title,
    standardSlideCtaTo: item.entity?.fieldStandardSlide?.entity?.fieldHeroCta?.url?.path
  }))

  const newsData = data.drupal.nodeById.fieldJournalPreview.entity?.fieldJournalSliderItems.map((item) => ({
    categories: item.entity.fieldNewsCategories.map((category) => category.entity.name),
    title: item.entity.fieldNewsTitle,
    date: item.entity.fieldNewsDate.date,
    ctaTo: item.entity.path.alias,
    background: isMobile ? item.entity.fieldNewsCoverMobile?.url : item.entity.fieldNewsCover?.url
  }))

  const socialData = data.drupal.nodeById.fieldHomeJoinOurCommunity?.entity

  const getSocialList = _ => {
    const socials = []
    for (let i = 0; i < socialData.fieldSocialPostsList.entity.fieldSocialPostItem.length; i++) {
      const item = socialData.fieldSocialPostsList.entity.fieldSocialPostItem[i].entity

      socials.push({
        url: item.fieldSocialPostItemLink.uri,
        coverImg: item.fieldSocialPostItemCoverImg.url,
        coverAlt: item.fieldSocialPostItemCoverImg.alt,
        logo: item.fieldSocialPostLogo.url,
        logoAlt: item.fieldSocialPostLogo.alt,
        postUsername: item.fieldSocialPostItemUsername,
        postSocialAccount: item.fieldSocialPostUserAccount.title,
        postSocialAccountUrl: item.fieldSocialPostUserAccount.url.path
      })
    }
    return socials
  }

  const widgetCategory = data.drupal.nodeById.fieldHomeOurWinesList.map((category) => ({
    cat: category.entity.fieldWineProductCategoryName
  }))

  const products = data.drupal.productsQuery.entities

  const result = products.filter(element => {
    return widgetCategory.find(category => category.cat === element.name)
  })

  const productsSliderItems = result.map((category) => (
    {
      img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow.url,
      art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
      name: category.name,
      price: category.fieldWineCategoryFromPrice,
      colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
      colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
      title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
      text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
      path: category.fieldWineCatPrimaryVintage.entity.path.alias
    }
  ))

  const socialsList = getSocialList()

  const visitUsItems = data.drupal.nodeById.fieldHomeVisitUsBlock.entity?.fieldVisitUsItemList.map((item) => ({
    name: item.entity.fieldVisitUsItemTitle,
    background: isMobile ? item.entity.fieldVisitUsItemBackMobile?.url : item.entity.fieldVisitUsItemBackDesk?.url,
    backgroundAlt: isMobile ? item.entity.fieldVisitUsItemBackMobile?.alt : item.entity.fieldVisitUsItemBackDesk?.alt,
    image: item.entity.fieldVisitUsItemCoverImage?.url,
    imageAlt: item.entity.fieldVisitUsItemCoverImage?.alt,
    href: item.entity.fieldVisitUsItemCta?.url?.path
  }))

  const aboutData = data.drupal.nodeById.fieldHomeAboutUsBlock.entity

  const richResultSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    name: 'Cloudy Bay',
    url: 'https://wwww.cloudybay.com/'
  }

  return (
    <Layout entityBundle='home_page' path={ pagePath } pageType='Home' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='facebook-domain-verification' content='6ax59ka2bfebe3bx798zzajg31ebg8' />
        <meta name='description' content={ meta.description } />
        <link rel='canonical' href={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}/` } />
        <script type='application/ld+json'>
          {JSON.stringify(richResultSchema)}
        </script>
      </Helmet>
      <HomeHero slides={ heroData } />
      <WinesSlider
        id='textcercle1'
        title={ data.drupal.nodeById.fieldHomeOurWinesTitle }
        items={ productsSliderItems }
        linkTitle='Discover all the wines from the two Cloudy Bay wineries'
      />
      <Quote
        content={ data.drupal.nodeById?.fieldHomeManuscriptQuote }
      />
      <NewsSlider title={ data.drupal.nodeById.fieldJournalPreview.entity?.fieldJournalSliderTitle } slides={ newsData } />
      <BlockTextImages
        subtitle={ aboutData?.fieldStandardTextBlock.entity.fieldStandardSubtitle }
        title={ aboutData?.fieldStandardTextBlock.entity.fieldStandardTitle }
        text={ aboutData?.fieldStandardTextBlock.entity.fieldStandardDescription }
        actions={ [
          <Button
            key={ 1 }
            to={ aboutData?.fieldStandardTextBlock.entity.fieldStandardCta?.url?.path }
          >{aboutData?.fieldStandardTextBlock.entity.fieldStandardCta?.title}
          </Button>
        ] }
        display='big'
        images={ [
          aboutData?.fieldStandard2imagesBlock.entity.fieldLeftImage?.url,
          aboutData?.fieldStandard2imagesBlock.entity.fieldRightImage?.url
        ] }
        leftAlt={ aboutData?.fieldStandard2imagesBlock.entity.fieldLeftImage?.alt }
        rightAlt={ aboutData?.fieldStandard2imagesBlock.entity.fieldRightImage?.alt }
      />
      <WinerySlider
        id='textcercle2a'
        secondId='textcercle2b'
        label={ data.drupal.nodeById.fieldHomeVisitUsBlock.entity.fieldVisitUsSubtitle }
        items={ visitUsItems }
      />
      <Community
        title={ socialData.fieldSocialPostsList.entity.fieldSocialListTitle }
        subtitle={ socialData.fieldSocialPostsList.entity.fieldSocialListSubtitle }
        text={ socialData.fieldSocialPostsList.entity.fieldSocialListDescription }
        action={
          <CircularLabelButton
            id='textcercle3'
            linkTitle='Open our Instagram page in a new tab'
            label={ socialData.fieldSocialPostsList.entity.fieldSocialListCta?.title }
            to={ socialData.fieldSocialPostsList.entity.fieldSocialListCta?.url.path }
            target='_blank'
            nbLabels={ isMobile ? 3 : 5 }
          />
        }
        items={ socialsList }
      />
      <div id='store-locator'>

        <StoreLocator pageType='Home' />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    drupal {
      nodeById(id: "14") {
        title
        nid
        ... on Drupal_NodeHomePage {
          fieldHomeMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                fieldMetaDescription
                fieldMetaSlugUrl
                fieldMetaTagsBypassAgeGate
                fieldMetaTitle
              }
            }
          }
          fieldHomeJoinOurCommunity {
            entity {
              ... on Drupal_NodeStandardSocialPosts {
                fieldSocialPostsList {
                  entity {
                    ... on Drupal_ParagraphSocialPostItemList {
                      fieldSocialListCta {
                        uri
                        title
                        options
                        url{
                          path
                        }
                      }
                      fieldSocialPostItem {
                        entity {
                          ... on Drupal_ParagraphSocialPostItem {
                            fieldSocialPostLogo {
                              height
                              url
                              width
                              targetId
                              alt
                              title
                            }
                            fieldSocialPostItemLink {
                              uri
                              title
                              options
                            }
                            fieldSocialPostItemCoverImg {
                              url
                              alt
                            }
                            fieldSocialPostItemUsername
                            fieldSocialPostUserAccount {
                              title
                              url {
                                path
                              }
                            }
                          }
                        }
                      }
                      fieldSocialListTitle
                      fieldSocialListSubtitle
                      fieldSocialListDescription
                    }
                  }
                }
                fieldSocialPostsMarkets {
                  targetId
                }
              }
            }
          }
          fieldHomeHeroSlider {
            entity {
              ... on Drupal_ParagraphStandardFullwidthSlider {
                id
                fieldStandardSliderItem {
                  entity {
                    id
                    type {
                      targetId
                    }
                    ... on Drupal_ParagraphStandardCountdownSlider {
                      fieldRevealDate {
                        value
                        date
                      }
                      fieldHeroDescription
                      fieldBackgroundMobile {
                        alt
                        url
                      }
                      fieldBackgroundDesktop {
                        alt
                        url
                      }
                      fieldStandardSlide {
                        entity {
                          ... on Drupal_ParagraphHeroBlock {
                            fieldBackgroundDesktop {
                              alt
                              url
                            }
                            fieldBackgroundMobile {
                              alt
                              url
                            }
                            fieldHeroCta {
                              uri
                              title
                              options
                              url {
                                path
                              }
                            }
                            fieldHeroDescription
                            fieldHeroSubtitle
                            fieldHeroTitle
                          }
                        }
                      }
                    }
                    ... on Drupal_ParagraphHeroBlock {
                      id
                      fieldHeroTitle
                      fieldHeroSubtitle
                      fieldHeroDescription
                      fieldHeroCta {
                        title
                        url {
                          path
                        }
                      }
                      fieldBackgroundMobile {
                        alt
                        url
                      }
                      fieldBackgroundDesktop {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldJournalPreview {
            entity {
              ... on Drupal_ParagraphJournalSlider {
                id
                fieldJournalSliderTitle
                fieldJournalSliderItems {
                  entity {
                    ... on Drupal_NodeNews {
                      fieldNewsCategories {
                        entity {
                          name
                        }
                      }
                      fieldNewsTitle
                      fieldNewsDate {
                        value
                        date
                      }
                      fieldNewsCover {
                        alt
                        url
                      }
                      fieldNewsCoverMobile {
                        alt
                        url
                      }
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
          }
          fieldHomeAboutUsBlock {
            entity {
              ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                id
                fieldStandardTextBlock {
                  entity {
                    ... on Drupal_ParagraphStandardContentBlock {
                      id
                      fieldStandardTitle
                      fieldStandardSubtitle
                      fieldStandardDescription
                      fieldStandardCta {
                        url {
                          path
                        }
                        title
                      }
                      fieldStandardContentCta2Link {
                        url {
                          path
                        }
                        title
                      }
                    }
                  }
                }
                fieldStandard2imagesBlock {
                  entity {
                    ... on Drupal_ParagraphStandardTwoImagesBlock {
                      id
                      fieldLeftImage {
                        alt
                        height
                        width
                        title
                        url
                      }
                      fieldRightImage {
                        alt
                        height
                        title
                        url
                        width
                      }
                    }
                  }
                }
              }
            }
          }
          fieldHomeVisitUsBlock {
            entity {
              ... on Drupal_ParagraphVisitUsList {
                id
                fieldVisitUsSubtitle
                fieldVisitUsItemList {
                  entity {
                    ... on Drupal_ParagraphVisitUsItem {
                      id
                      fieldVisitUsItemTitle
                      fieldVisitUsItemCta {
                        title
                        url {
                          path
                        }
                      }
                      fieldVisitUsItemCoverImage {
                        alt
                        url
                      }
                      fieldVisitUsItemBackMobile {
                        alt
                        url
                      }
                      fieldVisitUsItemBackDesk {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldHomeManuscriptQuote
          fieldHomeOurWinesTitle
          fieldHomeOurWinesList {
          entity {
            ... on Drupal_TaxonomyTermWineCategory {
              tid
              fieldWineProductCategoryName
              fieldWproductCatDescription
            }
          }
          }
        }
      }
      productsQuery: taxonomyTermQuery(
        limit: 1000,
        offset: 0,
        sort: [{field: "weight", direction: ASC}],
        filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}
      ) {
        entities {
          ... on Drupal_TaxonomyTermWineCategory {
            name
            fieldWineCategorySlugUrl
            fieldWineCatPrimaryVintage {
              entity {
                ...on Drupal_NodeWineProduct {
                  nid
                  path {
                    alias
                  }
                  title
                  fieldProductDiscoverColorCta
                  fieldWineProductBackcolor
                  fieldWineProductBackillustrat {
                    url
                  }
                  fieldWineProductShortDesc
                  fieldWineProductName
                  fieldCategoryProductName
                  fieldWineProductDiscoverCta {
                    title
                  }
                  fieldWineProdPackshotShadow {
                    url
                  }
                }
              }
            }
            fieldWineCategoryFromPrice
          }
        }
      }
    }
  }
`

export default IndexPage
